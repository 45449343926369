import React from 'react'
import MomentUtils from '@date-io/moment'
import moment, { Moment } from 'moment'
import {
  createMuiTheme,
  TextFieldProps,
  MuiThemeProvider,
  ThemeOptions,
} from '@material-ui/core'
import {
  DatePicker as MuiDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import Schedule from '@material-ui/icons/Event'

import adminColors from '../../../styles/_admin-colors.scss'
import './DatePicker.scss'
import { TextButton } from '../TextButton'

type DateAdverb = 'yesterday' | 'today' | 'tomorrow'

interface LocaleProps {
  locale?: string
  fmtDateAdverb?: (adverb: DateAdverb) => string
  alwaysDisplayWeekday?: boolean
  iso?: boolean
}

interface TextProps {
  okLabel?: React.ReactNode
  cancelLabel?: React.ReactNode
  todayLabel?: React.ReactNode
}

type Props = {
  date?: Moment
  setDate: (d: Moment) => void
  disablePast?: boolean
  disableFuture?: boolean
  minDate?: Moment
  format?: string
} & TextProps &
  LocaleProps

const theme = {
  overrides: {
    MuiTabs: {
      indicator: {
        backgroundColor: adminColors.pickerBackgroundHover,
      },
    },
    MuiButton: {
      label: {
        color: '#000',
      },
    },
    MuiPickersDay: {
      current: {
        color: adminColors.pickerBackgroundHover,
      },
      daySelected: {
        backgroundColor: adminColors.pickerBackground,
        color: '#fff',
        '&:hover': {
          backgroundColor: adminColors.pickerBackgroundHover,
          color: '#000',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Barlow',
  },
  palette: {
    background: { default: '#fff', paper: '#fff' },
    primary: {
      main: adminColors.pickerBackground,
    },
  },
}

const DateButton: React.FC<TextFieldProps & LocaleProps> = ({
  value,
  onClick,
  locale,
  fmtDateAdverb,
  alwaysDisplayWeekday,
  iso,
}) => {
  const formatDate = (dateStr: string) => {
    const parsedDate = moment(dateStr)

    if (iso) {
      return parsedDate.toISOString().split('T')[0]
    }

    const now = moment()

    const compareDay = (d1: Moment, d2: Moment) => d1.isSame(d2, 'day')
    const compareWeek = (d1: Moment, d2: Moment) => d1.isSame(d2, 'week')
    const compareYear = (d1: Moment, d2: Moment) => d1.isSame(d2, 'year')

    const formatDay = () => {
      if (compareDay(now, parsedDate)) {
        return fmtDateAdverb?.('today') ?? 'Today'
      } else if (compareDay(now.add(1, 'day'), parsedDate)) {
        return fmtDateAdverb?.('tomorrow') ?? 'Tomorrow'
      } else if (compareDay(now.subtract(1, 'day'), parsedDate)) {
        return fmtDateAdverb?.('yesterday') ?? 'Yesterday'
      } else if (compareWeek(now, parsedDate)) {
        return parsedDate
          .toDate()
          .toLocaleDateString(locale, { weekday: 'long' })
      } else {
        return parsedDate.toDate().toLocaleDateString(locale, {
          ...{
            day: 'numeric',
            month: 'numeric',
            year: !compareYear(now, parsedDate) ? 'numeric' : undefined,
          },
          ...(alwaysDisplayWeekday && { weekday: 'long' }),
        })
      }
    }

    return `${formatDay()}`
  }

  return (
    <div className="DateButton">
      <Schedule className="DateButton__icon" />
      <TextButton onClick={onClick ?? (() => undefined)}>
        {typeof value === 'string' ? formatDate(value) : ''}
      </TextButton>
    </div>
  )
}

const materialTheme = createMuiTheme(theme as ThemeOptions)

export const DatePicker: React.FC<Props> = ({
  date: selectedDate,
  setDate: setSelectedDate,
  locale = 'en',
  fmtDateAdverb,
  okLabel,
  cancelLabel,
  todayLabel,
  alwaysDisplayWeekday = true,
  disablePast = true,
  disableFuture = false,
  minDate,
  iso = false,
}) => {
  return (
    <div className="DatePicker">
      <MuiThemeProvider theme={materialTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
          <MuiDatePicker
            TextFieldComponent={(props) =>
              DateButton({
                ...props,
                fmtDateAdverb,
                locale,
                alwaysDisplayWeekday,
                iso,
              })
            }
            label="Day"
            inputVariant="filled"
            value={selectedDate}
            openTo="date"
            format={moment.defaultFormat}
            onChange={(d) => setSelectedDate(d ?? moment().startOf('hours'))}
            showTodayButton
            views={['date']}
            disablePast={disablePast}
            disableFuture={disableFuture}
            okLabel={okLabel}
            cancelLabel={cancelLabel}
            todayLabel={todayLabel}
            minDate={minDate}
          />
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    </div>
  )
}

export default DatePicker
