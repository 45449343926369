import React from 'react'
import './Dot.scss'
import { Tooltip } from '../Tooltip/Tooltip'
import classNames from 'classnames'

const dotVariants = [
  'success',
  'warning',
  'error',
  'orange',
  'grey',
  'blue',
  'purple',
] as const
const dotVariantSet = new Set(dotVariants)
export type DotVariant = typeof dotVariants[number]

export interface DotProps extends React.HTMLAttributes<HTMLDivElement> {
  variant: DotVariant
}

const getDotStyle = (variant: DotVariant) => {
  const isPredefinedVariant = dotVariantSet.has(variant)

  return isPredefinedVariant
    ? undefined
    : {
        backgroundColor: isPredefinedVariant ? undefined : variant,
      }
}

export const Dot: React.FC<DotProps> = (props) => {
  const { variant, title, ...restProps } = props

  const dotStyle = getDotStyle(variant)

  const className = classNames('Dot', {
    [`Dot__${props.variant}`]: !dotStyle,
  })

  return title ? (
    <Tooltip placement="top" title={title}>
      <span className={className} style={dotStyle} {...restProps} />
    </Tooltip>
  ) : (
    <span className={className} style={dotStyle} {...restProps} />
  )
}

export interface DotsProps {
  dots: DotOptions[]
}

export interface DotOptions {
  variant: DotVariant
  title?: string
}

export const Dots: React.FC<DotsProps> = ({ dots }) => {
  if (dots.length === 0) {
    return null
  }

  return (
    <div
      className={classNames('Dots', [
        {
          Dots__animated: dots.length > 1,
        },
      ])}
    >
      {dots.map(({ variant, title }, index) => {
        const dotStyle = getDotStyle(variant)

        return (
          <Dot
            title={title}
            key={index}
            variant={variant}
            style={{ marginLeft: `${index - 6}px`, ...dotStyle }}
          />
        )
      })}
    </div>
  )
}
