import classNames from 'classnames'
import React from 'react'
import './Flex.scss'

interface FlexProps {
  gap?: '0' | '5' | '10' | '15' | '30'
  direction?: 'column' | 'row'
  grow?: boolean
  style?: React.CSSProperties
  className?: string
  wrap?: 'wrap' | 'nowrap' | 'wrap-reverse' | 'revert'
  center?: boolean
}
export const Flex: React.FC<FlexProps> = ({
  children,
  gap = '15',
  direction = 'row',
  grow = false,
  style = {},
  className,
  wrap,
  center,
}) => {
  return (
    <div className={classNames('Flex', `${className || ''}`)}>
      <div
        className={classNames(
          'Flex__Content',
          `Flex__Content--gap-${gap ?? 15}`,
          grow && `Flex__Content--grow`,
        )}
        style={
          {
            flexDirection: direction,
            ...(gap && { '--gap': `${gap}px` }),
            ...(wrap && { flexWrap: wrap }),
            ...(center &&
              direction === 'row' && {
                alignItems: 'center',
              }),
            ...style,
          } as any
        }
      >
        {children}
      </div>
    </div>
  )
}

interface FlexItemProps {
  className?: string
  style?: React.CSSProperties
  grow?: number
  inline?: boolean
}
export const FlexItem: React.FC<FlexItemProps> = ({
  children,
  className,
  style = {},
  grow = 1,
  inline = false,
}) => {
  return (
    <div
      className={classNames('FlexItem', className)}
      style={{
        flexGrow: grow,
        display: inline ? 'inline-flex' : 'flex',
        ...style,
      }}
    >
      {children}
    </div>
  )
}
