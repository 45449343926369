import moment, { Moment } from 'moment-timezone'
import { AllowedReservationTimes, Language, Weekday } from '../types'
import { weekDays } from '../constants'
import { localizations } from '../locales/dictionary'

export type MomentRange = {
  start: Moment
  end: Moment
}

export type TimeLike = Date | Moment | string | number

export type TimeLikeRange = {
  start: TimeLike
  end: TimeLike
}

export type BusinessDayMoment = Moment

export const forwardToBusinessDay = (m: Moment): BusinessDayMoment => {
  const isoWeekday = m.isoWeekday()

  return isoWeekday < 6 ? m : m.add(8 - isoWeekday, 'days')
}

export const atEndOfDay = (t: TimeLike, tz: string): boolean => {
  const m = moment(t).tz(tz)

  return m.clone().endOf('day').diff(m, 'seconds') <= 60
}

export const isTimeRangeCurrentAt = (
  range: TimeLikeRange,
  at: Moment,
): boolean => at.isSameOrAfter(range.start) && at.isBefore(range.end)

export const doTimeRangesOverlap = (
  range1: TimeLikeRange,
  range2: TimeLikeRange,
): boolean =>
  moment(range1.start).isBefore(range2.end) &&
  moment(range1.end).isAfter(range2.start)

type TimezonePresentationProps =
  | {
      showTimezone: boolean
    }
  | {
      showTimezoneUnlessEqual: string
    }
type TimeFormatHintProps = {
  language: Language
  showAsTimezone: string
} & TimezonePresentationProps

type FormatMomentProps = {
  time: TimeLike
} & TimeFormatHintProps

type FormatDateTimeRangeProps = {
  start: TimeLike
  end: TimeLike
} & TimeFormatHintProps

const asUtcOffset = (tz: string): string =>
  tz.match(/^[+-]/) ? tz : moment().tz(tz).format('Z')

const areTimezonesActuallySameTime = (tz1: string, tz2: string) =>
  asUtcOffset(tz1) === asUtcOffset(tz2)

const formatMomentWithFormat = (
  formatName: keyof typeof localizations.en.timeFormats,
  props: FormatMomentProps,
): string => {
  const { time, showAsTimezone, language } = props
  const showTimezone =
    'showTimezone' in props
      ? props.showTimezone
      : areTimezonesActuallySameTime(
          showAsTimezone,
          props.showTimezoneUnlessEqual,
        )

  return moment(time)
    .tz(showAsTimezone)
    .locale(language)
    .format(
      localizations[language].timeFormats[formatName] +
        (showTimezone ? ' (z)' : ''),
    )
}

export const formatDateTime = (props: FormatMomentProps): string =>
  formatMomentWithFormat('momentDateTimeFormat', props)

export const formatDate = (props: FormatMomentProps): string =>
  formatMomentWithFormat('momentDateFormat', props)

export const formatTime = (props: FormatMomentProps): string =>
  formatMomentWithFormat('momentTimeFullFormat', props)

export const formatDateTimeRange = (
  props: FormatDateTimeRangeProps,
): string => {
  const start = moment(props.start)
  const end = moment(props.end)

  const startFormatted = formatDateTime({
    ...props,
    time: start,
    showTimezone: false,
  })
  const endFormatted = start.isSame(end, 'day')
    ? formatTime({ ...props, time: end })
    : formatDateTime({ ...props, time: end })

  return `${startFormatted} - ${endFormatted}`
}

export const businessDaysDifference = (
  t1: BusinessDayMoment,
  t2: BusinessDayMoment,
  unit?: moment.unitOfTime.Diff,
): number => {
  const t1withoutWeekends = t1
    .clone()
    .subtract(2 * (t1.isoWeek() - t2.isoWeek()), 'days')

  return t1withoutWeekends.diff(t2, unit ?? 'days')
}

const rounder = {
  up: Math.ceil,
  down: Math.floor,
  round: Math.round,
}

export const roundTo = (
  value: number,
  interval: number,
  roundUpOrDown?: 'up' | 'down',
) => rounder[roundUpOrDown ?? 'round'](value / interval) * interval

export const roundTimeDifference = (
  t1: Moment,
  t2: Moment,
  interval: number,
  unit: moment.unitOfTime.Diff,
  roundUpOrDown?: 'up' | 'down',
) => roundTo(t1.diff(t2, unit), interval, roundUpOrDown)

export const roundMoment = (
  m: Moment,
  interval: number,
  unit: moment.unitOfTime.Diff,
  roundUpOrDown?: 'up' | 'down',
): Moment =>
  m.set(unit, roundTo(m.get(unit), interval, roundUpOrDown)).startOf(unit)

// Using 1 + 'hours' unit doesn't always round up for some reason. Using 60 with 'minutes' does
export const ceilDateTime = (
  // result is new moment in local timezone
  date: Moment,
  delta: number,
  unit: 'hours' | 'minutes' | 'seconds',
): Moment => roundMoment(moment(date), delta, unit, 'up')

export const getWeekdayFromMoment = (date: Moment): Weekday => {
  return weekDays[date.isoWeekday() - 1]
}

export const formatTimeString = (randomFormatClockTimeString: string): string =>
  randomFormatClockTimeString
    .split(/\D+/)
    .slice(0, 2)
    .map((n) => `00${n}`.slice(-2))
    .join(':')

export const isTimeBetween = (
  comparedMoment: Moment,
  [start, end]: AllowedReservationTimes[Weekday],
) => {
  if (!start || !end) return false

  const momentFormatted = comparedMoment.format('HH:mm')

  return (
    formatTimeString(start) <= momentFormatted &&
    momentFormatted < formatTimeString(end)
  )
}

export const isReservationTimeAllowed = (
  reservationTime: Moment,
  allowedReservationTimes: AllowedReservationTimes,
): boolean => {
  const weekDay = getWeekdayFromMoment(reservationTime)
  const allowedTimesOfDay = allowedReservationTimes[weekDay] ?? false

  return allowedTimesOfDay && isTimeBetween(reservationTime, allowedTimesOfDay)
}

export const formatRelativeDay = (
  d: moment.Moment,
  currentLanguage: Language,
  referenceDate: moment.Moment = moment(),
) => {
  const translations = localizations[currentLanguage]

  if (d.isSame(referenceDate, 'day')) {
    return translations.today
  } else if (d.isSame(referenceDate.clone().add(1, 'days'), 'day')) {
    return translations.tomorrow
  } else {
    return formatDate({
      time: d,
      language: currentLanguage,
      showAsTimezone: moment(d).format('z'),
      showTimezone: false,
    })
  }
}

export const formatShortIsoTime = (t: TimeLike, keepOffset?: boolean): string =>
  moment
    .parseZone(t)
    .toISOString(keepOffset ?? true)
    .replace(/(T..:..):..(\.\d+)?/, '$1')

export const nextBusinessDay = (day?: Moment): BusinessDayMoment =>
  forwardToBusinessDay((day ?? moment()).add(1, 'day'))

export const isStartOfDay = (m: Moment): boolean =>
  m.format('HH:mm:ss') === '00:00:00'
export const isEndOfDay = (m: Moment): boolean =>
  m.format('HH:mm:ss') === '23:59:59'
